html, body
{
    padding:0;
    margin:0;
    width:100%;
    height:100%;
}

#app-root
{
    position:absolute;
    width:100%;
    height:100%;
    overflow:hidden;
    background:#eee;

    /*top padding for navigation bar*/
    box-sizing: border-box;
    padding-top:3.5em;
}

.navbar
{
    background:#1976d2;
}

.fullrow, .fullcol
{
    height:100%;
    overflow: auto;
    padding:0;
    margin:0;
}

@media (min-width: 768px)
{
    .col-md-0
    {
        display:none;
    }
}

@media (min-width: 576px) and (max-width: 767px)
{
    .col-sm-0
    {
        display:none;
    }
}

@media (max-width: 575px)
{
    .col-0
    {
        display:none;
    }
}



.cardspage-viewheader
{
    height: 3em;
    padding: 5px;
    box-sizing: border-box;
}

.cardspage-viewcontent
{
    padding: 0;
    height: calc(100% - 3em);
}

.dynamic-list
{
    overflow: auto;
    height:100%;
}

/*.cardspage-viewheader .col >*+* {*/
.cardspage-viewheader button:not(:first-child),
.cardspage-viewheader .row > :not(:first-child)
 {
    margin-left: 0.25em;
}

.cardspage-viewcontent .list-group-item
{
    padding: 0.125em 0.25em;
}

.cardspage-viewcontent input
{
    padding:0;
    line-height: 1;
    height:auto;
}

.cardspage-viewcontent .form-control-plaintext
{
    line-height: 1;
}

svg.bi 
{
    font-size: 1.75em;
    margin: -0.25em;
    margin-top: -0.375em; 
}

/*.split-view .split-pane {
    height: 100%;
}*/

/*
@media (max-width: 767px) {
    .splitpane-inactive {
        display:none;
    }
}

@media (min-width: 768px) {
    .splitpane-back {
        display:none;
    }
}*/

/*
.lang-link
{
    margin:0 0.25em;
    font-size:1.25em;
}


.container-fluid {
    height:100%;
}
.row {
    height:100%;
}
.col, .col-md {
    padding:0;
    height:100%;
    overflow:auto;
}
*/
